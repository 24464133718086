import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import "filepond/dist/filepond.min.css"
import Papa from "papaparse"
import React, { useEffect, useState } from "react"
import { FilePond, registerPlugin } from "react-filepond"
import { Button, Message } from "semantic-ui-react"

registerPlugin(FilePondPluginFileValidateType)

const postPilgrimsUrl =
  process.env.NODE_ENV === "production"
    ? "/.netlify/functions/postpilgrims"
    : "http://localhost:9000/postpilgrims"

const AdminActions = () => {
  const [pilgrimData, setPilgrimData] = useState(null)
  const [message, setMessage] = useState(null)

  const onUpdateFiles = async fileItems => {
    if (fileItems.length === 0) {
      setPilgrimData(null)
      return
    }

    const pilgrimsCsv = fileItems[0].file

    // Parse CSV
    Papa.parse(pilgrimsCsv, {
      header: true,
      delimiter: ",",
      skipEmptyLines: true,
      complete: result => {
        // console.log('[parsedResult]:', result.data)

        setPilgrimData(result.data)

        // TODO: Check for errors before upload
      },
    })
  }

  const handleUpload = async () => {
    const json = JSON.stringify({ pilgrimData })
    // Send data to cloud function
    try {
      const result = await fetch(postPilgrimsUrl, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: json,
      })

      const res = await result.json()
      setMessage(res.msg)
    } catch (err) {
      console.log(err)
    }
  }

  console.log("pilgrimData:", pilgrimData)
  useEffect(() => {})
  return (
    <div style={{ width: 400, margin: "3rem auto" }}>
      <p>Pilgrim CSV:</p>
      <FilePond
        acceptedFileTypes={["text/csv"]}
        onupdatefiles={onUpdateFiles}
      />
      <Button positive size="large" onClick={handleUpload}>
        Upload
      </Button>
      {message && (
        <Message positive>
          <Message.Content>{message}</Message.Content>
        </Message>
      )}
    </div>
  )
}

export default AdminActions
